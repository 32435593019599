import { useContext, useState } from 'react';

import Stepper from './StepLabel.js';
/* import Modal from '../UI/Modal'; */
/* import CartItem from './CartItem'; */
import classes from './Cart.module.css';
import CartContext from '../../store/cart-context';

import Footer from '../Layout/Footer';

import Destinatario from './Destinatario.js';

const isNotEmpty = (value) => value.trim() !== '';
const isTell = (value) => value.trim().length >= 9 && value.trim().length <= 11;

const isCpfValido = (value) => {
  // Remove caracteres não numéricos
  const cpfNumerico = value.replace(/\D/g, '');

  // Verifica se o CPF tem 11 dígitos
  if (cpfNumerico.length !== 11) {
    return false;
  }

  // Verifica se todos os dígitos são iguais (CPF inválido)
  const cpfArray = Array.from(cpfNumerico);
  if (new Set(cpfArray).size === 1) {
    return false;
  }

  // Calcula os dígitos verificadores do CPF
  let soma = 0;
  for (let i = 0; i < 9; i++) {
    soma += parseInt(cpfArray[i]) * (10 - i);
  }
  let resto = (soma * 10) % 11;

  if (resto === 10 || resto === 11) {
    resto = 0;
  }

  if (resto !== parseInt(cpfArray[9])) {
    return false;
  }

  soma = 0;
  for (let i = 0; i < 10; i++) {
    soma += parseInt(cpfArray[i]) * (11 - i);
  }
  resto = (soma * 10) % 11;

  if (resto === 10 || resto === 11) {
    resto = 0;
  }

  if (resto !== parseInt(cpfArray[10])) {
    return false;
  }

  return true;
};


/* const isCnpjValido = (value) => {
  // Remove caracteres não numéricos
  const cnpjNumerico = value.replace(/\D/g, '');

  // Verifica se o CNPJ tem 14 dígitos
  if (cnpjNumerico.length !== 14) {
    return false;
  }

  // Verifica se todos os dígitos são iguais (CNPJ inválido)
  const cnpjArray = Array.from(cnpjNumerico);
  if (new Set(cnpjArray).size === 1) {
    return false;
  }

  // Calcula os dígitos verificadores do CNPJ
  const calcDV = (cnpjArray, posicoes) => {
    let soma = 0;
    const peso = [6, 5, 4, 3, 2, 9, 8, 7, 6, 5, 4, 3, 2];
    
    for (let i = 0; i < posicoes; i++) {
      soma += parseInt(cnpjArray[i]) * peso[i + (posicoes - 12)];
    }

    const resto = soma % 11;
    return (resto < 2) ? 0 : 11 - resto;
  };

  const dv1 = calcDV(cnpjArray, 12);
  if (dv1 !== parseInt(cnpjArray[12])) {
    return false;
  }

  const dv2 = calcDV(cnpjArray, 13);
  if (dv2 !== parseInt(cnpjArray[13])) {
    return false;
  }

  return true;
};
 */

const isCnpjValido = (value) => {
  // Remove caracteres não numéricos
  const cnpjNumerico = value.replace(/\D/g, '');

  // Verifica se o CNPJ tem 14 dígitos
  if (cnpjNumerico.length !== 14) {
    return false;
  }

  // Verifica se todos os dígitos são iguais (CNPJ inválido)
  const cnpjArray = Array.from(cnpjNumerico);
  if (new Set(cnpjArray).size === 1) {
    return false;
  }

  // Calcula o primeiro dígito verificador (12º dígito)
  let soma = 0;
  let peso = 5;
  for (let i = 0; i < 12; i++) {
    soma += parseInt(cnpjArray[i]) * peso;
    peso = peso === 2 ? 9 : peso - 1;
  }

  let resto = soma % 11;
  let dv1 = resto < 2 ? 0 : 11 - resto;

  if (dv1 !== parseInt(cnpjArray[12])) {
    return false;
  }

  // Calcula o segundo dígito verificador (13º dígito)
  soma = 0;
  peso = 6;
  for (let i = 0; i < 13; i++) {
    soma += parseInt(cnpjArray[i]) * peso;
    peso = peso === 2 ? 9 : peso - 1;
  }

  resto = soma % 11;
  let dv2 = resto < 2 ? 0 : 11 - resto;

  if (dv2 !== parseInt(cnpjArray[13])) {
    return false;
  }

  return true;
};

const CartDelivery = (props) => {
  const [isValid, setIsValid] = useState(false);
  const [erro, setErro] = useState(false);
  const [encomendaFeita, setencomendaFeita] = useState(false);

  const [nomeisValid, setnomeIsValid] = useState(false);
  const [telefoneisValid, settelefoneIsValid] = useState(false);
  const [enderecoisValid, setenderecoIsValid] = useState(false);
  const [cpfisValid, setcpfIsValid] = useState(false);
  /*   const [cnpjisValid, setcnpjIsValid] = useState(false); */
  const [pagamentoisValid, setpagamentoIsValid] = useState(false);

  const cartCtx = useContext(CartContext);

  const totalAmount = `R$${cartCtx.totalAmount.toFixed(2)}`;
  const hasItems = cartCtx.items.length > 0;

  const alterarForm = (data) => {
    setnomeIsValid(isNotEmpty(data.nome));
    settelefoneIsValid(isTell(data.telefone));
    setenderecoIsValid(isNotEmpty(data.endereco));
    /*     console.log('isCpfValido(data.cpf)');
        console.log(isCpfValido(data.cpf));
        console.log("isCnpjValido(data.cpf)");
        console.log(isCnpjValido(data.cpf));
        console.log(data.cpf); */
    const isValidID = isCpfValido(data.cpf) || isCnpjValido(data.cpf);

    setcpfIsValid(isValidID);

    setpagamentoIsValid(isNotEmpty(data.pagamento));

    if (isNotEmpty(data.nome) && isTell(data.telefone) && isNotEmpty(data.endereco) && (isCpfValido(data.cpf) || isCnpjValido(data.cpf))/* isCpfValido(data.cpf) */ && isNotEmpty(data.pagamento)) {
      setIsValid(true);
      /*       console.log("VALIDO") */
    }
    else {
      /*       console.log("INVALIDO") */
      setIsValid(false);
    }

    props.onDeliveryChange(data)
  };


  /* const comprar = () => {
    var msgerro = "Verifique:"
    if(isValid)
    {props.onBuy();
    setencomendaFeita(true);}
    else{
    if(!nomeisValid) msgerro = msgerro + " NOME;";
    if(!telefoneisValid) msgerro = msgerro + " TELEFONE;";
    if(!enderecoisValid) msgerro = msgerro + " ENDEREÇO;";
    if(!cpfisValid) msgerro = msgerro + " CPF;";
    if(!pagamentoisValid) msgerro = msgerro + " PAGAMENTO;";
  
    setErro(msgerro);}
  
  }; */

  const comprar = () => {
    /*   var msgerro = "Verifique:" */
    if (isValid) {
      props.onBuy();
      setencomendaFeita(true);
      setErro(false);
    }
    else {
      /*   if(!nomeisValid) msgerro = msgerro + " NOME;";
        if(!telefoneisValid) msgerro = msgerro + " TELEFONE;";
        if(!enderecoisValid) msgerro = msgerro + " ENDEREÇO;";
        if(!cpfisValid) msgerro = msgerro + " CPF;";
        if(!pagamentoisValid) msgerro = msgerro + " PAGAMENTO;";
       */
      setErro(true);
    }

  };

  return (
    <div className={classes.cart_div} onClose={props.onClose}>
      <h2 className={classes.titulocartentrega}> ENTREGA </h2>

      {!encomendaFeita && <div><Stepper status={1}/></div>}
      {encomendaFeita  && <div><Stepper status={2}/></div>}
      <section className={classes.cart_section}>
        {/* {cartItems}
      <div className={classes.frete}>
        <span>Frete</span>
        <span>{freteAmount}</span>
      </div>*/}



        {!encomendaFeita && <div>
          
          <Destinatario onErro={erro} onErroNome={nomeisValid} onErroTelefone={telefoneisValid}
            onErroEndereco={enderecoisValid} onErroCPF={cpfisValid} onErroPagamento={pagamentoisValid}
            onFormChange={(data) => { alterarForm(data); }} />
          <div className={classes.total}>
            <span>Valor Total</span>
            <span>{totalAmount}</span>
          </div>

          {/*       <p>Pagamento é realizado na entrega</p> */}

          {/* {erro && <p className={classes.erro}>{erro}</p>} */}

          <div className={classes.actions}>
            <button className={classes['button--alt']} onClick={props.onBack}>
              Voltar
            </button>

            {hasItems && <button className={classes.button} onClick={() => { comprar(); }}>Encomendar</button>}
          </div>
        </div>}



        {encomendaFeita && <div>
          <h1 className={classes.done}>Sua encomenda foi feita com SUCESSO!</h1>
          <p>Entraremos em contato via whatsapp para informar os detalhes</p>
        </div>}



      </section>

      <Footer />

    </div>
  );
};

export default CartDelivery;
