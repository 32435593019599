import { /* useContext, */ useState } from 'react';

import Modal from '../UI/Modal.js';
/* import CartItem from './CartItem'; */
import classes from './Cart.module.css';
/* import CartContext from '../../store/cart-context.js'; */

import {
  editProduto
} from '../../server/sgbd.js';

const CartEditProduto = (props) => {
/*   const [id, setId] = useState(props.produtoED.produtoid); */

  const [nome, setNome] = useState(props.produtoED.nome);
  const [descricao, setDescricao] = useState(props.produtoED.descricao);
  const [estoque, setEstoque] = useState(props.produtoED.estoque);
  const [preco, setPreco] = useState(parseFloat(props.produtoED.preco));
  const [tipo, setTipo] = useState(props.produtoED.tipo);
  const [visivel, setVisivel] = useState(props.produtoED.visivel);
const id = props.produtoED.produtoid;
  console.log("receba");
  console.log(props.produtoED);

/*   if(props.produto)
  {
    setNome(props.nome);
    setDescricao(props.descricao);
    setEstoque(props.estoque);
    setPreco(props.preco);
    setTipo(props.tipo);
    setVisivel(props.visivel);
  }
 */
/*   useEffect(() => {
    const fetchProduto = async () => {
      const produtos = await fetchProdutos();
      const produto = produtos.find(p => p.produtoId === produtoId);
      setProduto(produto);
    };

    fetchProduto();
  }, [produtoId]); */

  /* const handleSubmit = async (e) => {
    e.preventDefault();

    const result = await editProduto(produto.produtoId, produto);

    if (result) {
      alert('Produto editado com sucesso!');
    } else {
      alert('Erro ao editar produto');
    }
  };

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setProduto({
      ...produto,
      [name]: type === 'checkbox' ? checked : value
    });
  }; */

  const handleSubmit = async (e) => {
    e.preventDefault();

    const produtoEditado = {
      id,
      nome,
      descricao,
      estoque,
      preco,
      tipo,
      visivel
    };

    const result = await editProduto(produtoEditado);

    if (result) {
      props.onClose();
      // Limpar o formulário ou redirecionar, conforme necessário
    } 
  };




  return (
    <Modal onClose={props.onClose}>

      <h2 className={classes.titulocartentrega}> EDITAR PRODUTO </h2>

      <form onSubmit={handleSubmit}>
      <div>
        <label>Nome:</label>
        <input
          type="text"
          value={nome}
          onChange={(e) => setNome(e.target.value)}
          required
        />
      </div>
      <div>
        <label>Descrição:</label>
        <input
          type="text"
          value={descricao}
          onChange={(e) => setDescricao(e.target.value)}
          required
        />
      </div>
      <div>
        <label>Estoque:</label>
        <input
          type="number"
          value={estoque}
          onChange={(e) => setEstoque(Number(e.target.value))}
          required
        />
      </div>
      <div>
        <label>Preço:</label>
        <input
          type="number"
          step="0.01"
          value={preco}
          onChange={(e) => setPreco(Number(e.target.value))}
          required
        />
      </div>
      <div>
        <label>Tipo:</label>
        <input
          type="text"
          value={tipo}
          onChange={(e) => setTipo(e.target.value)}
          required
        />
      </div>
      <div>
        <label>Visível:</label>
        <input
          type="checkbox"
          checked={visivel}
          onChange={(e) => setVisivel(e.target.checked)}
        />
      </div>
      <div><button type="submit">Editar Produto</button></div>
      
    </form>


      {/* <div className={classes.actions}>
          <button className={classes['button--alt']} onClick={props.onBack}>Anterior</button>
          <button className={classes.button} onClick={() => { adicionarNovoProduto(); }}>Adicionar</button>
        </div> */}







    </Modal>
  );
};

export default CartEditProduto;
