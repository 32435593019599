import classes from './Footer.module.css';
import { useState/* , useContext */ } from 'react';
/* import Modal from '../UI/Modal';

import Politica from './Politica.js';
import Termos from './Termos.js'; */

const Footer = (props) => {

     const [politicaIsShown, setPoliticaIsShown] = useState(false); 

    return (

    
        <div className={classes.footer} >

        {/* {politicaIsShown&& <Modal> <Politica></Politica> </Modal>} */}
        <p>&copy; 2024 Laticinio Garota.</p>
        <p>Todos os direitos reservados.</p>
{/*         <p>
            <a href="mailto:bufalasgarota@gmail.com">bufalasgarota@gmail.com</a> &nbsp;/ &nbsp;
            <a href="tel:77998170013">+55 77 99817-0013</a>
        </p> */}
{/*          <p>
            <button onClick={() => setPoliticaIsShown(!politicaIsShown) }  href="/politica-de-privacidade">Política de Privacidade</button>&nbsp;&nbsp;/ &nbsp;
            <button onClick={() => setPoliticaIsShown(!politicaIsShown) }  href="/termos-de-uso">Termos de Uso</button>
        </p>  */}
{/*         <p>
            <a href="https://facebook.com"><img src="facebook-icon.png" alt="Facebook"></a>
            <a href="https://instagram.com"><img src="instagram-icon.png" alt="Instagram"></a>
            <a href="https://twitter.com"><img src="twitter-icon.png" alt="Twitter"></a>
        </p> */}
    </div>
);

};

export default Footer;