import React, { /* Component, */ useState, useEffect} from 'react';
import './styles.css';

const Destinatario = (props) => {
/* class Destinatario extends Component {  */
      /* constructor() {
         super();
         
     }  */
     const [nome, setnome] = useState("");
     const [cpf, setcpf] = useState("");
     const [endereco, setendereco] = useState("");
     const [telefone, settelefone] = useState("");
     const [pagamento, setpagamento] = useState("");


/*      var nome = "";
     var cpf = "";
     var endereco = "";
     var telefone = "";
     var pagamento = "";  */
     /* state = {
        nome: '',
        cpf: '',
        endereco: '',
        telefone: '',
        pagamento: ''
    }; */
    useEffect(() => {
        const state = {nome, cpf,endereco,telefone,pagamento};
/*         console.log("state");
        console.log(state); */
        props.onFormChange(state);

      }, [nome,cpf,endereco,telefone,pagamento]);

    const handleChange = (e) => {
        const { name, value } = e.target;
        if (name === "nome") setnome(value);
        if (name === "cpf")  setcpf(value);
        if (name === "endereco")  setendereco(value);
        if (name === "telefone")  settelefone(value);
        if (name === "pagamento")  setpagamento(value);        
    }

    const handleSubmit = (e) => {
        e.preventDefault();
    }

    /* render() {  */
    return (
        <div>
            {/* <h2>Formulário de Cadastro</h2> */}
            <form onSubmit={handleSubmit}>
                <div>
                    <label>Nome: {props.onErroNome===false && props.onErro && <span style={{color: 'red'}}>(*)</span>}</label>
                    <input
                        type="text"
                        name="nome"
                        /* value={nome} */
                        onInput={handleChange}
                    />
                </div>
                <div>
                    <label>CPF/CNPJ: {props.onErroCPF===false && props.onErro && <span style={{color: 'red'}}>(*)</span>}</label>
                    <input
                        type="text"
                        name="cpf"
                        /* value={cpf} */
                        onChange={handleChange}
                    />
                </div>
                <div>
                    <label>Endereço: {props.onErroEndereco===false && props.onErro && <span style={{color: 'red'}}>(*)</span>}</label>
                    <input
                        type="text"
                        name="endereco"
                        /* value={endereco} */
                        onChange={handleChange}
                    />
                </div>
                <div>
                    <label>Telefone: {props.onErroTelefone===false && props.onErro && <span style={{color: 'red'}}>(*)</span>}</label>
                    <input
                        type="text"
                        name="telefone"
                        /* value={telefone} */
                        onChange={handleChange}
                    />
                </div>
                <div>
                    <label>Escolha a Forma de Pagamento {props.onErroPagamento===false && props.onErro && <span style={{color: 'red'}}>(*)</span>}</label>
                    <select name="pagamento" id="formaPagamento" onChange={handleChange}>
                        <option value="">SELECIONAR</option>
                        <option value="credito">CARTÃO DE CREDITO</option>
                        <option value="debito">CARTÃO DE DEBITO</option>
                        <option value="pix">PIX</option>
                        <option value="pix">DINHEIRO</option>
                        <option value="pix">BOLETO</option>
                    </select>
                </div>
                {/* <button type="submit">Enviar</button> */}
            </form>
                
        </div>
    );
    /*  }  */
}

export default Destinatario;