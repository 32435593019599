import classes from './Funcionarios.module.css';
import Footer from './Footer';

import Login from '../Log/Login';

import foto1 from '../../assets/FotoLaticinio(3).png';

const Contato = (props) => {

    return (

        <div className={classes.funcionarios_div} >

            <img src={foto1} alt="foto"></img>
            <section className={classes.funcionarios_section} id="contato">

                <h3 className={classes.textotitulo}>Conheça Nossa Equipe</h3>
                <p className={classes.texto}>
                    No Laticínio Garota, acreditamos que a qualidade dos nossos produtos é reflexo direto do comprometimento e dedicação da nossa equipe. Nosso time é composto por profissionais altamente qualificados, que se empenham diariamente para garantir que você receba sempre o melhor.
                </p>
                <p className={classes.texto}>
                    Desde os especialistas em produção até o pessoal da administração e logística, cada colaborador desempenha um papel fundamental na nossa operação. Trabalhamos em conjunto para manter os mais altos padrões de qualidade, higiene e segurança alimentar.
                </p>
                <p className={classes.texto}>
                    Nossa equipe de produção é formada por técnicos e engenheiros experientes, que utilizam tecnologia de ponta e seguem rigorosos processos de controle de qualidade para produzir laticínios frescos e deliciosos. Na administração, contamos com profissionais dedicados que gerenciam eficientemente nossas operações diárias, assegurando que tudo funcione perfeitamente.
                </p>
                <p className={classes.texto}>
                    Além disso, nosso time de logística trabalha incansavelmente para garantir que nossos produtos cheguem até você com a máxima frescura e pontualidade. Estamos sempre prontos para ouvir e atender às necessidades dos nossos clientes, pois acreditamos que a satisfação do cliente é a nossa maior recompensa.
                </p>
                <p className={classes.texto}>
                    Venha nos conhecer e descubra mais sobre as pessoas que fazem do Laticínio Garota uma referência em qualidade e sabor. Estamos aqui para servir você com o melhor que a natureza tem a oferecer.
                </p>

            </section>
            <div className={classes.AppFormFuncionario}>
            <Login onLogin={props.onLogin} />
            </div>
            <Footer />
        </div>
    );

};

export default Contato;