import { useContext } from 'react';

import Stepper from './StepLabel.js';
/* import Sugestions from './Sugestions.js'; */

/* import Modal from '../UI/Modal'; */
import CartItem from './CartItem';
import classes from './Cart.module.css';
import CartContext from '../../store/cart-context';

import Footer from '../Layout/Footer';
/* import Destinatario from './Destinatario.js'; */

const Cart = (props) => {
  const cartCtx = useContext(CartContext);

  const freteAmount = `R$10.00`;
  const totalAmount = `R$${cartCtx.totalAmount.toFixed(2)}`;
  const hasItems = cartCtx.items.length > 0;

  const cartItemRemoveHandler = (id) => {
    cartCtx.removeItem(id);
  }; 

  const cartItemAddHandler = (item) => {
    cartCtx.addItem({ ...item, amount: 1 });
  };

  const cartNext = () => {
    props.onNext(cartCtx);
  };

  const cartItems = (
    <ul className={classes['cart-items']}>
      {cartCtx.items.map((item) => (
        <CartItem
          key={item.id}
          name={item.name}
          amount={item.amount}
          price={item.price}
          onRemove={cartItemRemoveHandler.bind(null, item.id)}
          onAdd={cartItemAddHandler.bind(null, item)}
        />
      ))}
    </ul>
  );

  return (
    <div  className={classes.cart_div} onClose={props.onClose}>
      <h2 className={classes.titulocart}> CARRINHO </h2>
      <Stepper status={0}/>
      
      <section className={classes.cart_section}>

      {cartItems}

      {!hasItems && <h2 className={classes.carrovazio} > O seu carrinho está vazio no momento. Explore nossos produtos e adicione os itens desejados para que você possa finalizar sua compra com sucesso.</h2>}

      {hasItems && <div className={classes.frete}>
        <span>Frete</span>
        <span>{freteAmount}</span>
      </div>}

      {hasItems && <div className={classes.total}>
        <span>Valor Total</span>
        <span>{totalAmount}</span>
      </div>}

      {/* <Destinatario /> */}

      {hasItems && <div className={classes.actions}>
        <button className={classes['button--alt']} onClick={props.onClose}>
          Voltar
        </button>


        <button className={classes.button} onClick={() => { cartNext(); }}>Proximo</button>
      </div>}

      {!hasItems && <div className={classes.actions}>
        <button className={classes['button--addi']}  onClick={props.onClose} >Adicionar</button>
      </div>}

      </section>
      {/* <Sugestions goToItem={(e) => props.goToItem(e)} /> */}
      <Footer />
    </div>
  );
};

export default Cart;
