import classes from './MealsSummary.module.css';

import SlideShow from '../SlideShow/SlideShow.js'; // Importe seu arquivo CSS para estilos
import { useState/* , useContext */ } from 'react';


const MealsSummary = (props) => {
  const [busca, setBusca] = useState('');

/*   useEffect(() => {
    setBusca(props.setValue);
    props.setTermo(props.setValue);
      }, [props.setValue]); */


  const handleInputChange = (event) => {
    setBusca(event.target.value);
    props.setTermo(event.target.value);
  };



  return (
    <section className={classes.summary}>
      {/*  <p className={classes.frase1}>ACOMPANHAR PEDIDO</p> */}

{/*       <p className={classes.frase1}>SEU QUEIJO FAVORITO ESTÁ AQUI!</p>
      <p className={classes.frase2}>FAÇA SEU PEDIDO!</p> */}

      <SlideShow />

      <input
        type="text"
        value={busca}
        onChange={handleInputChange}
        /* onChange={(e) => {setBusca(e.target.value); props.setTermo(e.target.value); }}  */
        placeholder="Pesquisar produtos"
      />

      {/*       <input
        type="text"
        placeholder="Pesquisar produtos"
      /> */}

      {/* <h2>Bebidas Refrescantes Entregues na Sua Porta</h2>
      <p id='headermenu'>
      Explore uma ampla variedade de opções de bebidas e desfrute de suas favoritas sem sair de casa.
      </p>
      <p>
      Produtos de alta qualidade e entrega rapida para garantir uma experiência deliciosa.
      </p> */}
    </section>
  );
};

export default MealsSummary;
