import React/*,  { useState }  */ from 'react';
import './StepLabel.css';

const steps = ['Car', 'End', 'Con'];

const StepLabel = ({ label, isActive, isCompleted }) => {
    return (
        <div className={`step ${isActive ? 'active' : ''} ${isCompleted ? 'completed' : ''}`}>
            {label === 'Car' &&<div className="step-label"> <svg  className="svg" xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px" fill="#5f6368"><path d="M280-80q-33 0-56.5-23.5T200-160q0-33 23.5-56.5T280-240q33 0 56.5 23.5T360-160q0 33-23.5 56.5T280-80Zm400 0q-33 0-56.5-23.5T600-160q0-33 23.5-56.5T680-240q33 0 56.5 23.5T760-160q0 33-23.5 56.5T680-80ZM246-720l96 200h280l110-200H246Zm-38-80h590q23 0 35 20.5t1 41.5L692-482q-11 20-29.5 31T622-440H324l-44 80h480v80H280q-45 0-68-39.5t-2-78.5l54-98-144-304H40v-80h130l38 80Zm134 280h280-280Z"/></svg></div>}
            {label === 'End' && <div className="step-label"><svg  className="svg" xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px" fill="#5f6368"><path d="M240-160q-50 0-85-35t-35-85H40v-440q0-33 23.5-56.5T120-800h560v160h120l120 160v200h-80q0 50-35 85t-85 35q-50 0-85-35t-35-85H360q0 50-35 85t-85 35Zm0-80q17 0 28.5-11.5T280-280q0-17-11.5-28.5T240-320q-17 0-28.5 11.5T200-280q0 17 11.5 28.5T240-240ZM120-360h32q17-18 39-29t49-11q27 0 49 11t39 29h272v-360H120v360Zm600 120q17 0 28.5-11.5T760-280q0-17-11.5-28.5T720-320q-17 0-28.5 11.5T680-280q0 17 11.5 28.5T720-240Zm-40-200h170l-90-120h-80v120ZM360-540Z" /></svg></div>}
            {label === 'Con' &&<div className="step-label"> <svg  className="svg" xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px" fill="#5f6368"><path d="M382-240 154-468l57-57 171 171 367-367 57 57-424 424Z"/></svg></div>}
            </div>
    );
};

const Stepper = (props) => {
/*     const [currentStep, setCurrentStep] = useState(props.status); */
    var currentStep = props.status;
/*     const handleNext = () => {
        if (currentStep < steps.length - 1) {
            setCurrentStep(currentStep + 1);
        }
    };

    const handleBack = () => {
        if (currentStep > 0) {
            setCurrentStep(currentStep - 1);
        }
    }; */

    return (
        <div className="stepper">
            <div className="stepper-labels">
                {steps.map((label, index) => (
                    <StepLabel
                        key={index}
                        label={label}
                        isActive={index === currentStep}
                        isCompleted={index <= currentStep}
                    />
                ))}
            </div>
            {/*       <div className="stepper-content">
        <p>{`Conteúdo da ${steps[currentStep]}`}</p>
      </div> 
      <div className="stepper-controls">
         <button onClick={handleBack} disabled={currentStep === 0}>
          Voltar
        </button>
        <button onClick={handleNext} disabled={currentStep === steps.length - 1}>
          Próximo
        </button>
      </div> */}
        </div>
    );
};

export default Stepper;
