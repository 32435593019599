import React from 'react';
import classes from './PedidoCard.module.css';

import { format } from 'date-fns';
import { ptBR } from 'date-fns/locale';

const PedidoCard = (props) => {
  const { pedido_status,cliente_nome, telefone/* , status */, endereco , pagamento_status , cpf, cnpj, itens, total ,pedidoid, datapedido} = props.pedido;
/* console.log(props.pedido);
console.log("renderPedido"); */

const formatDate = (dateString) => {
  return format(new Date(dateString), "dd 'de' MMMM 'de' yyyy 'às' HH:mm", { locale: ptBR });
};

  return (
     <div className={classes.pedidocard}>
      {pedido_status==='AGUARDANDO PAGAMENTO' && <h2>Pedido de {cliente_nome}</h2>}
      {pedido_status==='ENTREGUE' && <h2 className={classes.h2green}>Pedido de {cliente_nome}</h2>}
      {pedido_status==='CANCELADO' && <h2 className={classes.h2red}>Pedido de {cliente_nome}</h2>}
      {pedido_status==='PROCESSANDO PEDIDO' && <h2 className={classes.h2blue}>Pedido de {cliente_nome}</h2>}

      <p>Telefone: {telefone}</p>

{/*       {status==='AGUARDANDO PAGAMENTO' && <p>Status: <font color="darkblue"> {status}</font></p>}
      {status==='FEITO' && <p>Status: <font color="green"> {status}</font></p>}
      {status==='CANCELADO' && <p>Status: <font color="red"> {status}</font></p>} */}
      
      <p>Endereço: {endereco}</p>
      {cpf && <p>CPF: {cpf}</p>}
      {cnpj && <p>CNPJ: {cnpj}</p>}
      <p>Pagamento: {pagamento_status}</p>
      <p>Status: {pedido_status}</p>
      <p>Data do Pedido: {formatDate(datapedido)}</p>

      <h3>Itens do Pedido:</h3>
      <ul>
        {itens && itens.map((item,index) => (
          <li key={item.produtoId || index}>
           {item.nome} - Qtd: {item.quantidade} - Preço: R$ {item.valor}
          </li>
        ))}
      </ul>

      <p>Total do Pedido: R$ {total}</p>
      <div className={classes.botoes}>
      { pagamento_status==='AGUARDANDO' &&  <button className={classes.blue} onClick={() => props.pagamento(pedidoid)} >PAGO</button>}
      
      { pedido_status==='PROCESSANDO PEDIDO' &&  <button className={classes.green} onClick={() => props.entregue(pedidoid)} >ENTREGUE</button>}
      { pedido_status==='PROCESSANDO PEDIDO' &&  <button className={classes.red}  onClick={() => props.cancelado(pedidoid)} >CANCELADO</button>}</div>
    </div> 
  );
}

export default PedidoCard;