import { Fragment } from 'react';

import MealsSummary from './MealsSummary';
import AvailableMeals from './AvailableMeals';
import { /* useEffect, */ useState/* , useContext */ } from 'react';
import Footer from '../Layout/Footer.js';

const Meals = (props) => {
  const [termo, setTermo] = useState('');



/*   useEffect(() => {
    console.log(props.setBusca);
    setTermo(props.setBusca);
    
      }, [props.setBusca]); */

  
  return (
    <Fragment>
      <MealsSummary /* setValue={termo} */ setTermo={(e) => setTermo(e)}  />
      <AvailableMeals /* carrinho={props.carrinho}  */setTermo={termo}/>
      
      <Footer/>
    </Fragment>
  );
};

export default Meals;
