import classes from './Sobre.module.css';
import Footer from './Footer';

import foto1 from '../../assets/FotoLaticinio(1).png';

const Sobre = (props) => {

    return (

        <div className={classes.sobre_div}>
            <img src={foto1} alt="foto"></img>

            <section className={classes.sobre_section} id="sobre">

                <h3 className={classes.textotitulo}>Bem-vindo ao Laticínio Garota!</h3>
                <p className={classes.texto}>Somos uma empresa dedicada à produção de laticínios de alta qualidade, comprometida com a excelência em cada etapa do processo. Nossa história começou em 2009, com o objetivo de trazer para a mesa dos nossos clientes produtos frescos e saborosos, respeitando as tradições e os métodos artesanais que garantem a autenticidade dos nossos queijos e derivados.</p>

                <p className={classes.texto}>Aqui no Laticínio Garota, trabalhamos com uma equipe apaixonada e experiente, que se empenha diariamente para garantir que nossos produtos atendam aos mais altos padrões de qualidade. Nossos laticínios são produzidos com leite fresco, proveniente de fazendas selecionadas que seguem rigorosos critérios de qualidade.</p>
                <p className={classes.texto}>Nossa missão é proporcionar aos nossos clientes uma experiência única de sabor e frescor, oferecendo uma variedade de queijos, iogurtes, manteigas e outros produtos derivados do leite, feitos com carinho e dedicação.</p>

                <h3 className={classes.textotitulo}>Prêmios e Reconhecimentos</h3>
                <p className={classes.texto}>Estamos orgulhosos das conquistas e prêmios que nossos produtos receberam:</p>

                <h3 className={classes.texto}>Araxá International Cheese Awards (2024)</h3>
                <ul>
                    <li><strong className={classes.bronze}>BRONZE:</strong> Minas Frescal</li>
                </ul>

                <h3 className={classes.texto}>XVII Encontro Nordestino do Setor de Leite e Derivados (2023)</h3>
                <ul>
                    <li><strong className={classes.gold}>OURO:</strong> Mozzarella de Búfala Condimentada</li>
                    <li><strong className={classes.gold}>OURO:</strong> Doce de Leite de Búfala</li>
                    <li><strong className={classes.silver}>PRATA:</strong> Mozzarella de Búfala em Bolinhas</li>
                    <li><strong className={classes.silver}>PRATA:</strong> Mozzarella de Búfala em Bolinhas Condimentadas</li>
                    <li><strong className={classes.bronze}>BRONZE:</strong> Minas Frescal de Búfala</li>
                    <li><strong className={classes.bronze}>BRONZE:</strong> Fior di Latte</li>
                
                    <li><strong className={classes.bronze}>BRONZE:</strong> Coalho Trufado com Carne Seca</li>
                </ul>

            </section>

            <Footer />
        </div>
    );

};

export default Sobre;