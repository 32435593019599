// Este arquivo encapsula as funções de requisição HTTP utilizando fetch nativo

const baseURL = 'https://laticinios-garota.store/api.php'; // Altere conforme a URL da sua API

//Função para adicionar um pedido
export const registrarPedidoCPF = async (pedidoData) => {
  try {
    const response = await fetch(`${baseURL}?endpoint=registrarPedidoCPF`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(pedidoData),
    });

    
    if (!response.ok) {
      throw new Error('Erro ao registrar pedido');
    }

    const data = await response.json();
    return data; // Pode retornar o ID do pedido ou outro dado relevante
  } catch (error) {
    console.error('Erro ao registrar pedido:', error);
    throw error; // Propaga o erro para ser tratado pelo componente que chamou a função
  }
};

//Função para adicionar um pedido
export const registrarPedidoCNPJ = async (pedidoData) => {
  try {
    const response = await fetch(`${baseURL}?endpoint=registrarPedidoCNPJ`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(pedidoData),
    });

    if (!response.ok) {
      throw new Error('Erro ao registrar pedido');
    }

    const data = await response.json();
    return data; // Pode retornar o ID do pedido ou outro dado relevante
  } catch (error) {
    console.error('Erro ao registrar pedido:', error);
    throw error; // Propaga o erro para ser tratado pelo componente que chamou a função
  }
};

// Função para adicionar um administrador
/* export const addAdmin = async (nome, email, senha) => {
  try {
    const response = await fetch(`${baseURL}/admin`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ nome, email, senha }),
    });
    if (!response.ok) {
      throw new Error('Erro ao adicionar administrador');
    }
    const data = await response.json();
    return data;
  } catch (error) {
    console.error('Erro ao adicionar administrador:', error);
    return null;
  }
}; */

// Função para login de administrador
export const loginAdmin = async (email, senha) => {
  try {
    const response = await fetch(`${baseURL}?endpoint=adminLogin`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ email, senha }),
    });
    if (!response.ok) {
      const data = await response.json();
      throw new Error(data.message || 'Erro ao fazer login');
    }
    const data = await response.json();
    return data;
  } catch (error) {
    console.error('Erro ao fazer login:', error);
    return null;
  }
};

// Função para buscar todos os pedidos
export const fetchPedidos = async () => {
  try {
    const response = await fetch(`${baseURL}?endpoint=consultar-pedidos`);
    if (!response.ok) {
      throw new Error('Erro ao buscar pedidos');
    }
    const data = await response.json();
    return data;
  } catch (error) {
    console.error('Erro ao buscar pedidos:', error);
    return [];
  }
};

export const fetchProdutos = async (termo = '') => {
  try {
    const url = new URL(`${baseURL}?endpoint=produtos`);
    
    if (termo) {
      url.searchParams.append('termo', termo);
    }

    const response = await fetch(url);
/*     console.log('Response status:', response.status);
    console.log('Response ok:', response.ok); */

    if (!response.ok) {
/*       console.log("Erro");
      console.log('Response:', response); */
      throw new Error('Erro ao buscar produtos');
    }

    const data = await response.json();
/*     console.log("Data");  
    console.log(data); */
    return data;
  } catch (error) {

    console.error('Erro ao buscar produtos:', error);
    return [];
  }
};

export const addProduto = async (produto) => {
  try {
    const response = await fetch(`${baseURL}?endpoint=addproduto`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(produto),
    });
    if (!response.ok) {

      throw new Error('Erro ao adicionar produto');
    }
    const data = await response.json();
    return data;
  } catch (error) {
    console.error('Erro ao adicionar produto:', error);
    return null;
  }
};

// Função para finalizar compra
export const finalizarCompra = async (produtos) => {
  try {
    const response = await fetch(`${baseURL}/pedido/finalizar`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ produtos }),
    });
    if (!response.ok) {
      throw new Error('Erro ao finalizar compra');
    }
    // Você pode processar a resposta se necessário
  } catch (error) {
    console.error('Erro ao finalizar compra:', error);
  }
};

// Função para adicionar um novo produto
export const adicionarProduto = async (produto) => {
  try {
    const response = await fetch(`${baseURL}/produto/adicionar`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(produto),
    });
    if (!response.ok) {
      throw new Error('Erro ao adicionar produto');
    }
    // Retorna os dados do produto adicionado
    const data = await response.json();
    return data;
  } catch (error) {
    console.error('Erro ao adicionar produto:', error);
    return null;
  }
};

// Função para editar um produto
export const editProduto = async (produto) => {
  try {
    const response = await fetch(`${baseURL}/produtos/editarproduto`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(produto),
    });
    if (!response.ok) {
      throw new Error('Erro ao editar produto');
    }
    const data = await response.json();
    return data;
  } catch (error) {
    console.error('Erro ao editar produto:', error);
    return null;
  }
};

// Função para deletar um produto usando POST
export const deleteProduto = async (id) => {
  try {
    const response = await fetch(`${baseURL}/produto/deletar`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ id }),
    });
    if (!response.ok) {
      throw new Error('Erro ao deletar produto');
    }
    const data = await response.json();
    return data;
  } catch (error) {
    console.error('Erro ao deletar produto:', error);
    return null;
  }
};

// Função para buscar todos os clientes
export const fetchClientes = async () => {
  try {
    const response = await fetch(`${baseURL}/cliente/lista`);
    if (!response.ok) {
      throw new Error('Erro ao buscar clientes');
    }
    const data = await response.json();
    return data;
  } catch (error) {
    console.error('Erro ao buscar clientes:', error);
    return [];
  }
};

// Função para buscar o relatório de produtos vendidos
export const fetchRelatorioProdutosVendidos = async () => {
  try {
    const response = await fetch(`${baseURL}/relatorio/produtos-vendidos`);
    if (!response.ok) {
      throw new Error('Erro ao buscar relatório de produtos vendidos');
    }
    const data = await response.json();
    return data;
  } catch (error) {
    console.error('Erro ao buscar relatório de produtos vendidos:', error);
    return [];
  }
};

// Função para atualizar o status do pagamento
export const updatePaymentStatus = async (orderId, newStatus) => {
  try {
    const response = await fetch(`${baseURL}/orders/${orderId}/payment-status`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ payment_status: newStatus }),
    });

    if (!response.ok) {
      throw new Error('Erro ao atualizar o status do pagamento');
    }

    const data = await response.json();
    return data;
  } catch (error) {
    console.error('Erro ao atualizar o status do pagamento:', error);
    return null;
  }
};

// Função para atualizar o status do pedido
export const updateOrderStatus = async (orderId, newStatus) => {
  try {
    const response = await fetch(`${baseURL}/orders/${orderId}/order-status`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ order_status: newStatus }),
    });

    if (!response.ok) {
      throw new Error('Erro ao atualizar o status do pedido');
    }

    const data = await response.json();
    return data;
  } catch (error) {
    console.error('Erro ao atualizar o status do pedido:', error);
    return null;
  }
};
 
