/* import { Routes, Route, BrowserRouter, Navigate, Switch, useNavigate } from "react-router-dom"; */

/* import React, { useEffect, Fragment } from 'react'; */
/* import classes from './Header.module.css'; */
import './navbar.css';/* 
import MENU from '../icon/menu.svg' */
/* import logo from '../../img/Vasta.png';
import banner from '../../img/Banner.jpg';
import bannerMobile from '../../img/BANNER - IMAGE - MOBILE.png'; */

const MainHeader = (props) => {

/*   const update = () => {
    props.onChangeState(true);
  } */



    
  const toggleMenuOpen = () => { document.body.classList.toggle("open"); }
  var title = "LATICINIO GAROTA";

  return (
    <div className="headerstyle">
      
      <nav className="navbar">

        <div className="navbar-overlay" onClick={toggleMenuOpen}></div>

        {/* <img src={logo} onClick={() => { navigate("/"); }} className="navbar-title" alt="logo" /> */}
        <h1 className="nomeapp">{title}</h1>


        <nav className="navbar-menu">
          <button className="button" onClick={() => props.goToPedidos() }>PEDIDOS{/* <a href=""></a> */}</button> 
          <button className="button" onClick={() => props.goToProdutos()  } >PRODUTOS{/* <a href=""></a> */}</button>
          <button className="button" onClick={() => props.goToRelatorios()  } >RELATORIOS{/* <a href=""></a> */}</button>
         {/*  <button onClick={() => props.onCancel() } ><a href="#CANCELADO">CATEGORIAS</a></button> */}
          <button className="button"  onClick={() => props.onExit()  } >SAIR{/* <a href="">SAIR</a> */}</button>
        </nav>

        <svg className="material-symbols-outlined" onClick={() => toggleMenuOpen()} xmlns="http://www.w3.org/2000/svg" height="40px" viewBox="0 -960 960 960" width="40px" fill="#000000"><path d="M120-240v-66.67h720V-240H120Zm0-206.67v-66.66h720v66.66H120Zm0-206.66V-720h720v66.67H120Z"/></svg>
          
        {/* <span className="material-symbols-outlined"  onClick={() => toggleMenuOpen()}>menu</span>
         */}  
{/*         <img alt="menu" src={MENU} className="material-icons" onClick={() => toggleMenuOpen()}></img> */}
        {/* <span className="material-icons" onClick={() => toggleMenuOpen()}>menu</span> */}
        {/* <button type="button" className="navbar-burger" onClick={toggleMenuOpen}>
        
        </button> */}

      </nav> 



    </div>

  );
};

export default MainHeader;