import { useContext } from 'react';
import CartContext from '../../../store/cart-context';

import MealItemForm from './MealItemForm';
import classes from './MealItem.module.css';


const MealItem = (props) => {
   const cartCtx = useContext(CartContext); 

  /* const cartCtx =useContext(props.carrinho); */

  const price = `R$${props.price}`;

  const addToCartHandler = amount => {
    cartCtx.addItem({
      id: props.id,
      name: props.name,
      amount: amount,
      price: props.price
    });
  };

  return (
    <div> { (props.description !== "titulo" && props.description !== "titulo1") && <li className={classes.meal} id={props.id}>
      {/* <div className={classes.imgdrink}>
        <img src={vetorDeImports[pos]} alt='SEM IMAGEM'></img></div> */}
      <div className={classes.namedrink}> 
        <h3>{props.name}</h3>
        <div className={classes.description}>{props.description}</div>
        <div className={classes.price}>{price}</div>
      </div>
      <div className={classes.add}>
        <MealItemForm id={props.id} onAddToCart={addToCartHandler} />
      </div>
    </li>}

    { props.description === "titulo1" && <h1 id={props.id}  className={classes.tituloproduto1}>{props.name}</h1>}
    { props.description === "titulo" && <h1 id={props.id}  className={classes.tituloproduto}>{props.name}</h1>}

    </div>
  );
};

export default MealItem;
