import { Fragment, useEffect, useState } from 'react';
import classes from './Pedidos.module.css';
import HeaderAuth from '../Layout/MainHeader';

/* import CartAddProduto from '../Cart/CartAddProduto';
import CartEditProduto from '../Cart/CartEditProduto'; */

import {
    /* fetchProdutos, */
    fetchRelatorioProdutosVendidos
} from '../../server/sgbd';

const Relatorios = (props) => {

    const [relatorio, setRelatorio] = useState([]);

    useEffect(() => {
        const carregarRelatorio = async () => {
            const data = await fetchRelatorioProdutosVendidos();
            setRelatorio(data);
        };
        carregarRelatorio();
    }, []);

    return (
        <Fragment>



            <HeaderAuth goToPedidos={() => props.goToPedidos()}
                goToProdutos={() => props.goToProdutos()}
                goToRelatorios={() => props.goToRelatorios()}
                onExit={() => props.onExit()} />

            <div className={classes.exibirrelatorios} >
                <h1>Produtos Vendidos</h1>
                <div>
                <table>
                    <thead>
                        <tr>
                            <th>Nome do Produto</th>
                            <th>Tipo</th>
                            <th>Quantidade</th>
                            <th>Total(R$)</th>
                        </tr>
                    </thead>
                    <tbody>
                        {relatorio.map((produto, index) => (
                            <tr key={index}>
                                <td className={classes.exibirnome} >{produto.produto_nome}</td>
                                <td>{produto.produto_tipo}</td>
                                <td>{produto.quantidade_vendida}</td>
                                <td>{produto.total_vendido}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>
                </div>

            </div>



        </Fragment >
    );
};

export default Relatorios;