import { initStore } from './store';

const initialAuthState = {
  email: null,
  isAuthenticated: false,
  pageName: "/",
  erro: [],
  appName: "LaticiniosGarotaStore",
  appInstaName: "@laticinios.garota",
  loading: true,
  user: null,
  user_key:null
};

const configureStore = () => {
  const actions = {
    login: (curState) => {
      curState.isAuthenticated = true;
    },
    logout: (curState) => {
      curState.isAuthenticated = false;
    },
    updateAuth: (state, value) => {
      return value;
    }
  };

  initStore(actions, initialAuthState);
};

export default configureStore;