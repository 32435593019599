import { /* useContext, */ useState } from 'react';

import Modal from '../UI/Modal.js';
/* import CartItem from './CartItem'; */
import classes from './Cart.module.css';
/* import CartContext from '../../store/cart-context.js'; */

import {
  adicionarProduto
} from '../../server/sgbd';

const CartAddProduto = (props) => {
/*   const cartCtx = useContext(CartContext); */

  const [nome, setNome] = useState('');
  const [descricao, setDescricao] = useState('');
  const [estoque, setEstoque] = useState(0);
  const [preco, setPreco] = useState(0);
  const [tipo, setTipo] = useState('');
  const [visivel, setVisivel] = useState(true);



  const handleSubmit = async (e) => {
    e.preventDefault();

    const novoProduto = {
      nome,
      descricao,
      estoque,
      preco,
      tipo,
      visivel,
    };

    const result = await adicionarProduto(novoProduto);

    if (result) {
      /* alert('Produto adicionado com sucesso!'); */
      // Limpar o formulário ou redirecionar, conforme necessário
      setNome('');
      setDescricao('');
      setEstoque(0);
      setPreco(0);
      setTipo('');
      setVisivel(true);
    } /* else {
      alert('Erro ao adicionar produto');
    } */
  };

  return (
    <Modal onClose={props.onClose}>

      <h2 className={classes.titulocartentrega}> PRODUTO </h2>

      <form onSubmit={handleSubmit}>
      <div>
        <label>Nome:</label>
        <input
          type="text"
          value={nome}
          onChange={(e) => setNome(e.target.value)}
          required
        />
      </div>
      <div>
        <label>Descrição:</label>
        <input
          type="text"
          value={descricao}
          onChange={(e) => setDescricao(e.target.value)}
          required
        />
      </div>
      <div>
        <label>Estoque:</label>
        <input
          type="number"
          value={estoque}
          onChange={(e) => setEstoque(Number(e.target.value))}
          required
        />
      </div>
      <div>
        <label>Preço:</label>
        <input
          type="number"
          step="0.01"
          value={preco}
          onChange={(e) => setPreco(Number(e.target.value))}
          required
        />
      </div>
      <div>
        <label>Tipo:</label>
        <input
          type="text"
          value={tipo}
          onChange={(e) => setTipo(e.target.value)}
          required
        />
      </div>
      <div>
        <label>Visível:</label>
        <input
          type="checkbox"
          checked={visivel}
          onChange={(e) => setVisivel(e.target.checked)}
        />
      </div>
      <div><button type="submit">Adicionar Produto</button></div>
      
    </form>


        {/* <div className={classes.actions}>
          <button className={classes['button--alt']} onClick={props.onBack}>Anterior</button>
          <button className={classes.button} onClick={() => { adicionarNovoProduto(); }}>Adicionar</button>
        </div> */}
      

    




    </Modal>
  );
};

export default CartAddProduto;
