/* import { color } from 'framer-motion'; */
import React from 'react';
import { useEffect, useState } from 'react';
import { TailSpin as Loader } from 'react-loader-spinner';/* 
import 'react-loader-spinner/dist/loader/css/react-spinner-loader.css'; */

const LoadingSpinner = () => {
  const [loading, setLoading] = useState(true);

  useEffect(() => {

      setTimeout(() => {
        setLoading(false);
      }, 1000);

  }, []);

  return (
    loading && <div style={{zindex:-10 , background: 'white' ,display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
      <Loader type="TailSpin" color="#CD222B" height={100} width={100} />
    </div>
  );
}

export default LoadingSpinner;