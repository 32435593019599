/* import { Routes, Route, BrowserRouter, Navigate, Switch, useNavigate } from "react-router-dom"; */

/* import React, { useEffect, Fragment } from 'react'; */
import { Fragment } from 'react';
import './navbar.css';

/* import MENU from '../icon/menu.svg' */

import HeaderCartButton from './HeaderCartButton';
import classes from './Header.module.css';
import LOGO from '../../assets/LOGO.webp';

/* import logo from '../../img/Vasta.png';
import banner from '../../img/Banner.jpg';
import bannerMobile from '../../img/BANNER - IMAGE - MOBILE.png'; */

const Header = (props) => {

  /*   const update = () => {
      props.onChangeState(true);
    } */




  const toggleMenuOpen = () => { document.body.classList.toggle("open"); }

/*   var title = "LOJA"; */

  return (
    <Fragment>
      <header className={classes.header}>

        {/*     <div className="headerstyle"> */}

        <nav className="navbar"> 

          <div className="navbar-overlay" onClick={toggleMenuOpen}></div>

          {/* <h1 className="nomeapp">{title}</h1> */}
          {/* <span className="material-icons" onClick={() => toggleMenuOpen()}>menu</span> */}

          <div className="navbar_div_logo" >
          <svg className="material-symbols-outlined" onClick={() => toggleMenuOpen()} xmlns="http://www.w3.org/2000/svg" height="40px" viewBox="0 -960 960 960" width="40px" fill="#000000"><path d="M120-240v-66.67h720V-240H120Zm0-206.67v-66.66h720v66.66H120Zm0-206.66V-720h720v66.67H120Z"/></svg>
          
          {/* <span className="material-symbols-outlined"  onClick={() => toggleMenuOpen()}>menu</span>
           */}  {/* <img alt="menu" src={MENU} className="material-icons" onClick={() => toggleMenuOpen()}></img>
           */} {/* <span className="material-icons" onClick={() => toggleMenuOpen()}>menu</span> */} 
          <img alt="LOGO" src={LOGO} onClick={() => toggleMenuOpen()} style={{ width: '100px', height: '50px' }} /* onClick={() => navigate("/auth")} */></img>
</div>

          <nav className="navbar-menu">
            <img className="navbar-img-menu" onClick={() => {props.goToCardapio();toggleMenuOpen();}} alt="LOGO" src={LOGO} style={{ width: '100px', height: '50px' }}></img>
            <button onClick={() => {props.goToCardapio();toggleMenuOpen();}} className="button" >CARDÁPIO</button>
            <button onClick={() => {props.goToSobre();toggleMenuOpen();}} className="button" >SOBRE</button>
            <button onClick={() => {props.goToContato();toggleMenuOpen();}} className="button" >CONTATO</button>
            <button onClick={() => {props.goToFuncionarios();toggleMenuOpen();}} className="button" >{/* <span className="seta-menu">&gt;&nbsp;&nbsp;</span> */}FUNCIONÁRIOS</button>
          </nav>

          <HeaderCartButton onClick={props.onShowCart} />
        </nav>



        {/*     </div> */}
      </header>
    </Fragment>
  );
};

export default Header;