/* import React, { useState, useEffect } from 'react'; */
import classes from './Login.module.css';

/* import Card from '../UI/Card'; */
import useInput from '../hooks/use_input';


const isEmail = (value) => value.includes('@');
const isPassword = (value) => value.trim().length > 7;

const Login = (props) => {

  const {
    value: emailValue,
    isValid: emailIsValid,
    /* hasError: emailHasError, */
    valueChangeHandler: emailChangeHandler,
    inputBlurHandler: emailBlurHandler,
    reset: resetEmail,
  } = useInput(isEmail);
  const {
    value: passwordValue,
    isValid: passwordIsValid,
    /* hasError: passwordHasError, */
    valueChangeHandler: passwordChangeHandler,
    inputBlurHandler: passwordBlurHandler,
    reset: resetpassword,
  } = useInput(isPassword);

  let formIsValid = false;

  if (passwordIsValid && emailIsValid) {
    formIsValid = true;
  }

  const submitHandler = event => {
    /* event.preventDefault(); */
    if (!formIsValid) {
      return;
    }

    props.onLogin(emailValue, passwordValue);

    resetpassword();
    resetEmail();
  };

  return (
    <div className={classes.login}>

      <h2 className={classes.title}>Login de Funcionarios!</h2>
      <div>
        <input
          type="text"
          className="form-control" 
          onChange={emailChangeHandler}
          onBlur={emailBlurHandler}
          id="email"
          placeholder="Email"
          value={emailValue}
          autoComplete="off"></input>

        {/* <input
          type='text'
          id='email'
          value={emailValue}
          onChange={emailChangeHandler}
          onBlur={emailBlurHandler}
        />
        <label className={emailValue && 'filled'} htmlFor='email'>
          Email
        </label> */}
        {/* {emailHasError && <p className="error-text">Please enter a valid email address.</p>} */}
      </div>
      <div>
        <input
          type='password'
          id='password'
          className="form-control"
          value={passwordValue}
          onChange={passwordChangeHandler}
          onBlur={passwordBlurHandler}
          placeholder="Senha"
          autoComplete="off"></input>
        {/* {passwordHasError && <p className="error-text">Please enter a valid password.</p>} */}
      </div>

      <button
        onClick={submitHandler}
        disabled={!formIsValid}
        type="button"
        className={formIsValid ? "btn btn-light" : "btn btn-outline-light"}> Acessar </button>

    </div>
  );
};

export default Login;
