import { Fragment, useEffect, useState } from 'react';
/*import { initializeApp } from "firebase/app"; *//* 
import { getAnalytics } from "firebase/analytics"; */
/* import { useNavigate } from "react-router-dom"; */

/* import { onSnapshot, where, getFirestore, collection, setDoc, doc,  query, serverTimestamp } from "firebase/firestore";
 *//* import { storage } from "firebase/storage"; */
/* import { getAuth, createUserWithEmailAndPassword, signInWithEmailAndPassword, onAuthStateChanged, sendPasswordResetEmail, signOut, updateProfile } from "firebase/auth";
 */
import PedidoCard from './PedidoCard';

import classes from './Pedidos.module.css';

import HeaderAuth from '../Layout/MainHeader';

import {
    fetchPedidos,  updatePaymentStatus, updateOrderStatus
  } from '../../server/sgbd';
/* const firebaseConfig = {
    apiKey: "AIzaSyDcrfFSHP0WJfpJnUeArdrRyVb9NH44V5k",
    authDomain: "delivery-6cfd4.firebaseapp.com",
    projectId: "delivery-6cfd4",
    storageBucket: "delivery-6cfd4.appspot.com",
    messagingSenderId: "688119345969",
    appId: "1:688119345969:web:4aea374d969ced86d6f610",
    measurementId: "G-Y6YHVGS13X"
}; */
/* const app = initializeApp(firebaseConfig); */
/* const analytics = getAnalytics(app); */
/* const db = getFirestore(app);
 */
const Pedidos = (props) => {
/*     const navigate = useNavigate(); */
    /* const unsub = onSnapshot(collection(db, "pedidos"), (doc) => {
        console.log("Current data: ");
        atualizar();
    }); */
/*     const [tipolistaPedidos, setTipoListaPedidos] = useState('AGUARDANDO PAGAMENTO'); */
const tipolistaPedidos= 'AGUARDANDO PAGAMENTO';
/*     console.log("TIPO LISTA");
    console.log(tipolistaPedidos); */

    /* const a = query(collection(db, "pedidos") , where("status", "!=", "")  ); */

    const [pedidos, setPedidos] = useState([]);

    useEffect(() => {  
      fetchPedidosData();
    }, []);

/*     useEffect(() => {
        console.log("tipolistaPedidos");
        console.log(tipolistaPedidos);
      }, [tipolistaPedidos]); */



/*     useEffect(() => {
        
        const unsubscribe = onSnapshot(a, (querySnapshot) => {
            const data = [];
            console.log("LISTA ATUALIZADA");
            console.log(tipolistaPedidos);
            querySnapshot.forEach((doc) => {

                const id = doc.id
                const info = { ...doc.data(), id }
                
                data.push(info);
                setListaPedidos(data);
            });
        });
    }, [tipolistaPedidos]); */
    /*
        const atualizar = async () => {
            const q = query(collection(db, "pedidos"), where("status", "==", 'PROCESSANDO'));
    
            const querySnapshot = await getDocs(q);
            const data = [];
            querySnapshot.forEach((doc) => {
    
                // doc.data() is never undefined for query doc snapshots
                console.log(doc.id, " => ", doc.data());
                data.push(doc.data());
            });
            setListaPedidos(data);
        }; */

        const fetchPedidosData = async () => {
            try {
              const data = await fetchPedidos();
              setPedidos(data);
            } catch (error) {
              console.error('Erro ao buscar pedidos:', error);
            }
          };

        const marcarPedidoPago = async (orderId, newStatus) => {
            /* console.log("PAGO"); */
/*             console.log(pedidoId); */

            try {
                /* const updatedPaymentStatus =*/ await  updatePaymentStatus(orderId, newStatus);
                /* const updatedOrderStatus = */await  updateOrderStatus(orderId, 'PROCESSANDO PEDIDO');
                fetchPedidosData();
              } catch (error) {
                console.error('Erro ao atualizar o status do pagamento:', error);
              }
    /* 
            var horarioEntrega = serverTimestamp();
            var user = props.usuario.id; 
            var status = "FEITO";
    
            const pedidoRef = doc(db, 'pedidos', pedidoId);
            setDoc(pedidoRef, { status, horarioEntrega }, { merge: true }); */
        }

        
    const marcarPedidoRealizado = async (orderId) => {
        /* console.log("ENTREGUE"); */
        /*             console.log(pedidoId); */
        
                    try {
                        /* const updatedOrderStatus = */ await updateOrderStatus(orderId, 'ENTREGUE');
                        fetchPedidosData();
                      } catch (error) {
                        console.error('Erro ao atualizar o status do PEDIDO:', error);
                      }
/* 
        var horarioEntrega = serverTimestamp();
        var user = props.usuario.id; 
        var status = "FEITO";

        const pedidoRef = doc(db, 'pedidos', pedidoId);
        setDoc(pedidoRef, { status, horarioEntrega }, { merge: true }); */
    }

    // Função para atualizar o status do pedido para "ERRO"
    const marcarPedidoCancelado = async (orderId) => {
/*         console.log("CANCELADO"); */
        /*             console.log(pedidoId); */
        
                    try {
                        /* const updatedOrderStatus = */ await updateOrderStatus(orderId, 'CANCELADO');
                        fetchPedidosData();
                      } catch (error) {
                        console.error('Erro ao atualizar o status do PEDIDO:', error);
                      }
/* 
        var horarioEntrega = serverTimestamp();
        var user = props.usuario.id; 
        var status = "CANCELADO";

        const pedidoRef = doc(db, 'pedidos', pedidoId);
        setDoc(pedidoRef, { status, horarioEntrega }, { merge: true }); */
    }

    /* const selecionarPRO = () => {
        console.log('PROCESSANDO');
        setTipoListaPedidos('PROCESSANDO');
    }

    const selecionarDONE = () => {
        console.log('FEITO');
        setTipoListaPedidos('FEITO');
    } */

    /* const selecionarCANCEL = () => {
        console.log('CANCELADO');
        setTipoListaPedidos('CANCELADO');
    } */

    return (
        <Fragment>
            <HeaderAuth goToPedidos= {() => props.goToPedidos() }
            goToProdutos={() => props.goToProdutos() } 
            goToRelatorios={() => props.goToRelatorios()}
            onExit={() => props.onExit() } /* {singOutHandler} */ />

            
           {/*  <div className={classes.escolherStatus}>
        <button onClick={() => selecionarPRO()}>PROCESSANDO</button>
        <button onClick={() => selecionarDONE()}>FEITO</button>
        <button onClick={() => selecionarCANCEL()}>CANCELADO</button></div> */}

            <div className={classes.exibir}>{
            /* 
                <label>Escolha os pedidos Pagamento</label> */}
{/*                 <select name="status" id="tipoStatus" onMouseOut={(e) => selecionar(e)} onChange={(e) => selecionar(e)}>
                    <option value="PROCESSANDO">PROCESSANDO</option>
                    <option value="FEITO">FEITO</option>
                    <option value="CANCELADO">CANCELADO</option>
                </select> */}
        
                {pedidos.map((p, n) =>
                    <div key={p.pedidoid} className={classes.show} /* className={p.status === tipolistaPedidos ? classes.show :classes.hide} */>
                        <PedidoCard key={p.pedidoid} pedido={p} 
                        pagamento={(ID) => { marcarPedidoPago(ID, "PAGAMENTO AUTORIZADO"); }}
                        entregue={(ID) => { marcarPedidoRealizado(ID); }}
                        cancelado={(ID) => { marcarPedidoCancelado(ID); }} />
                    </div>)}
            </div>
        </Fragment>
    );
};

export default Pedidos;