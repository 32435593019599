import classes from './Contato.module.css';
import Footer from './Footer';

import foto1 from '../../assets/FotoLaticinio(2).png';

const Contato = (props) => {

    return (

        <div className={classes.contato_div} >

           <img src={foto1} alt="foto"></img>
            <section className={classes.contato_section} id="contato">
 

                <h3 className={classes.textotitulo}>CONTATO</h3>
                <p className={classes.texto}>Gostaríamos muito de ouvir você! Se tiver alguma dúvida, sugestão ou quiser saber mais sobre nossos produtos, não hesite em entrar em contato conosco. Estamos sempre à disposição para atender você da melhor maneira possível.</p>
                <p className={classes.texto}><strong>Telefone:</strong>  77 99817-0013</p>
                <p className={classes.texto}><strong>Email:</strong> bufalasgarota@gmail.com</p>
                <p className={classes.texto}><strong>Endereço:</strong> Av. Itarantim, 140, Sidney Almeida, Itambé/BA, CEP 45140-000</p>
                <p className={classes.texto}><strong>Redes Sociais:</strong> @laticinios.garota</p>
                <p className={classes.texto}>Nosso horário de atendimento é de 8:00 as 18:00 de segunda a sexta. {/* Se preferir, você também pode nos enviar uma mensagem diretamente pelo formulário abaixo, e retornaremos o mais breve possível. */}</p>

                {/*             <form action="[URL de envio do formulário]" method="post">
                <label for="nome">Nome:</label><br>
                <input type="text" id="nome" name="nome" required></input><br><br>

                <label for="email">Email:</label><br>
                <input type="email" id="email" name="email" required></input><br><br>

                <label for="mensagem">Mensagem:</label><br>
                <textarea id="mensagem" name="mensagem" rows="4" required></textarea><br><br>

                <input type="submit" value="Enviar"></input>
            </form> */}
            </section>

            <Footer />
        </div>
    );

};

export default Contato;