import React, { useState, useEffect } from 'react';
import './SlideShow.css'; // Importe seu arquivo CSS para estilos

import IMGBANNER1 from '../../assets/banner1.webp';
import IMGBANNER2 from '../../assets/banner2.webp';
import IMGBANNER3 from '../../assets/banner3.webp';

const SlideShow = () => {
  const [currentSlide, setCurrentSlide] = useState(0);

  // Array de imagens
  const slides = [
    IMGBANNER1,
    IMGBANNER2,
    IMGBANNER3,
    // Adicione mais imagens conforme necessário
  ];

  useEffect(() => {
    const intervalId = setInterval(() => {
      // Avançar para o próximo slide
      setCurrentSlide((prevSlide) => (prevSlide + 1) % slides.length);
    }, 5000); // Altere para ajustar o intervalo de tempo entre os slides (em milissegundos)

    // Limpeza do intervalo quando o componente é desmontado
    return () => clearInterval(intervalId);
  }, [currentSlide, slides.length]);

   const prevSlide = () => {
    // Retroceder para o slide anterior
    setCurrentSlide((prevSlide) => (prevSlide - 1 + slides.length) % slides.length);
  };

  const nextSlide = () => {
    // Avançar para o próximo slide
    setCurrentSlide((prevSlide) => (prevSlide + 1) % slides.length);
  }; 

  return (
    <div className="slideshow-container">
      {slides.map((slide, index) => (
        <div
          key={index}
          className={`mySlides fade ${index === currentSlide ? 'show' : ''}`}
        >
          <img src={slide} alt={`Slide ${index + 1}`} loading="lazy" /* width="800" */  height="250" />
        </div>
      ))}

       <button className="prev" onClick={prevSlide}>&#10094;</button>
      <button className="next" onClick={nextSlide}>&#10095;</button> 
    </div>
  );
};

export default SlideShow;